"use strict";

var App = {

    S: require('localstorage'),

    cmd: require('../cmd'),

    user: JSON.parse(require('localstorage').read('user')),


    analogType: {
        1: '<sup>o</sup> C',
        2: 'Vac',
        3: 'W',
        4: '%RH',
        5: 'kPa',
        6: 'cm',
        7: ' ',
        8: '<sup>o</sup> C',
        9: '%RH',
        10: '<sup>o</sup> C',
    },

    adc: function(dev){

        if ( dev.hasADCConverted ) return;

        var current_value = parseFloat(dev.current_value);
        //if ( isNaN(current_value) )  { return; //current_value = ''; }
        var analog_type = parseInt(dev.analog_type);
        var analog_error = dev.analog_error;
        var analog_fluid_density = parseFloat(dev.analog_fluid_density);
        if ( current_value != '' ) current_value = F.adc(current_value, analog_type, analog_error, analog_fluid_density);
        dev.current_state = '';
        dev.current_value = current_value;

        switch ( analog_type ) {
            case 1: // thermometer
            case 2: // VAC
            case 3: // W
            case 4: //%RH
            case 5: //kPa
            case 6: //cm
            case 7: //
            case 8: // temperature_tcs
            case 9: // humidity_tcs
            case 10: // thermistor_16bit
                dev.analogTP = App.analogType[analog_type];
                break;
            default:
        }

        dev.hasADCConverted = true;
    },



    calc: function(that, i, max, datatype){
        var inp = $(that).closest('div').find('input');
        var v = parseFloat(inp.val());
        if ( isNaN(v) ) return false;

        datatype = datatype || '';

        v = parseFloat(v + i).toFixed(2);

        if ( parseFloat(v) < 0 ) v = '0.00';

        if ( max && parseFloat(max) < parseFloat(v) ) v=parseFloat(max).toFixed(2);

        if ( datatype == 'int' ) v = parseInt(v);

        inp.val(v);
    },


    set_val: function(devCmd){

        var activeView = App.S.read('activeview');

        var inp = $(this).closest('div').find('input');

        var skey = $(this).attr('data-skey');
        var value = parseFloat(inp.val());
        if ( isNaN(value) ) return false;

        var el;
        if ( activeView == 'fav' ) el = App.D[skey];
        if ( activeView == 'spaces' ) el = App.D.devices[skey];

        if ( el.analog_type && el.analog_type > 0 ) {
            var analog_type = parseInt(el.analog_type);
            var analog_error = parseFloat(el.analog_error);
            var analog_fluid_density = parseFloat(el.analog_fluid_density);
            value = F.toADC(parseFloat(value), analog_type, analog_error, analog_fluid_density);
        }

        //if ( parseInt(value) == 0 && isNaN(parseInt(old_value)) ) old_value = 10;
        //if ( parseInt(value) > 0 && isNaN(parseInt(old_value)) ) old_value = 1;
        if ( value !== null ) {
            var user = JSON.parse(require('localstorage').read('user'));
             var k = {
                  idb: parseInt(el.idb),
                  idu: parseInt(el.idu),
                  rno: parseInt(el.rno),
                  rtype: el.rtype,
                  command: devCmd, //(the type: report, relay, cardun etc.)
                  cmd: 'set', //(the command: on/off/click etc.)
                  value: parseInt(value), //(the value if command is SET)
                  //old_value: old_value, //(the old value if command is SET)
                  uid: user.gateway, //
             }
            if ( skey.indexOf('enh_counter_var') > -1 ) {
                k.cmd = 'val';
            }
            else if ( skey.indexOf('enh_counter_lim') > -1 ) {
                k.cmd = 'lim';
            }


             App.cmd.rawCmd(k);

         }

        App.show_content();
    },


    show_content: function(){
        $('div.content').empty().html(App.CONTENT);
        $(document).ready(function(){
            // Align right positioned elements.
            $('a.a-outer').css('margin-right', '-112px');
        });
    },

    show_ajax_wait: function(){
        var tmpl = require('views/ajax-wait');
        $('div.content').empty().html(tmpl);
    },

    show_err: function(e){
        var tmpl = require('views/error');
        $('div.content').empty().html(tmpl({data:e}));
    },


    relay: function(){
        var activeView = App.S.read('activeview');
        var state;
        var skey = $(this).attr('data-skey');
        if ( activeView == 'fav' ) {
            state = $(this).hasClass('active') ? 1 : 0;
            App.cmd.onOff(skey, App.D[skey]);
        }

        if ( activeView == 'spaces' ) {
            state = $(this).attr('data-state');
            App.cmd.onOff(skey, App.D.devices[skey]);
        }

    },

    macro: function(){
        var id_macro = $(this).attr('data-macro-id');
        App.cmd.macroCmd(App.MACROS[id_macro]);
    },


    button: function(){
        var activeView = App.S.read('activeview');
        var skey = $(this).attr('data-skey');
        if ( activeView == 'fav' ) {
            App.cmd.onOff(skey, App.D[skey]);
        }
        if ( activeView == 'spaces' ) {
            App.cmd.onOff(skey, App.D.devices[skey]);
        }

    },

    set_dimmer: function(){
        var activeView = App.S.read('activeview');
        var skey = $(this).attr('data-skey');
        var devCmd = skey.split(',').pop();

        var _tpl = require('views/fav/cmd_dimmer_set');

        var data = {
            label: '',
            current_value: '',
            skey: skey,
        };
        if ( activeView == 'fav' ) {
            data.label = App.D[skey].label;
            data.current_value = App.D[skey].current_value;
        }
        if ( activeView == 'spaces' ) {
            data.label = App.D.devices[skey].label;
            data.current_value = App.D.devices[skey].current_value;
        }

        $('div.content').empty().html(_tpl(data));


        $(document).off('click', '.set-dimmer-down').on('click', '.set-dimmer-down', function(){App.calc(this, -1, 100);});
        $(document).off('click', '.set-dimmer-up').on('click', '.set-dimmer-up', function(){App.calc(this, 1, 100);});
        $(document).off('click', '.set-dimmer-back').on('click', '.set-dimmer-back', function(){App.calc(this, -10, 100);});
        $(document).off('click', '.set-dimmer-forward').on('click', '.set-dimmer-forward', function(){App.calc(this, 10, 100);});
        $(document).off('click', '.set-dimmer-button').on('click', '.set-dimmer-button', function(){
            //~ App.set_val.apply(this, ['dimmer']);
            App.set_val.apply(this, [devCmd]);
        });
    },


    set_enh: function(){
        var activeView = App.S.read('activeview');
        var skey = $(this).attr('data-skey');

        var _tpl = require('views/fav/cmd_enh_set');

        var data = {
            label: '',
            current_value: '',
            skey: skey,
        };
        if ( activeView == 'fav' ) {
            data.label = App.D[skey].label;
            data.current_value = App.D[skey].current_value;
        }
        if ( activeView == 'spaces' ) {
            data.label = App.D.devices[skey].label;
            data.current_value = App.D.devices[skey].current_value;
        }

        $('div.content').empty().html(_tpl(data));


        $(document).off('click', '.set-enh-down').on('click', '.set-enh-down', function(){App.calc(this, -1, 35535, 'int');});
        $(document).off('click', '.set-enh-up').on('click', '.set-enh-up', function(){App.calc(this, 1, 35535, 'int');});
        $(document).off('click', '.set-enh-back').on('click', '.set-enh-back', function(){App.calc(this, -100, 35535, 'int');});
        $(document).off('click', '.set-enh-forward').on('click', '.set-enh-forward', function(){App.calc(this, 100, 35535, 'int');});
        $(document).off('click', '.set-enh-button').on('click', '.set-enh-button', function(){
            App.set_val.apply(this, ['enh_counter']);
        });
    },

    set_thermostat: function(){
        var activeView = App.S.read('activeview');
        var skey = $(this).attr('data-skey');

        var _tpl = require('views/fav/cmd_thermostat_set');

        var data = {
            label: '',
            current_value: '',
            skey: skey,
        };
        if ( activeView == 'fav' ) {
            data.label = App.D[skey].label;
            data.current_value = App.D[skey].current_value;
        }
        if ( activeView == 'spaces' ) {
            data.label = App.D.devices[skey].label;
            data.current_value = App.D.devices[skey].current_value;
        }

        $('div.content').empty().html(_tpl(data));

        $(document).off('click', '.set-temp-down').on('click', '.set-temp-down', function(){App.calc(this, -1);});
        $(document).off('click', '.set-temp-up').on('click', '.set-temp-up', function(){App.calc(this, 1);});
        $(document).off('click', '.set-temp-back').on('click', '.set-temp-back', function(){App.calc(this, -10);});
        $(document).off('click', '.set-temp-forward').on('click', '.set-temp-forward', function(){App.calc(this, 10);});
        $(document).off('click', '.set-temp-button').on('click', '.set-temp-button', function(){
            App.set_val.apply(this, ['adc_threshold1']);
        });
    },

    // Bind ONCE all events
    bind: function(caller){

        var activeView = App.S.read('activeview');

        $(document).off('click', '.cmd-set-go-back').on('click', '.cmd-set-go-back', App.show_content);

        $(document).off('click', '[data-rtype="relay"]').on('click', '[data-rtype="relay"]', App.relay);
        $(document).off('toggle', '[data-rtype="relay"]').on('toggle', '[data-rtype="relay"]', App.relay);

        if ( activeView == 'fav' ) {
            $(document).off('click', '.set-thermostat').on('click', '.set-thermostat', App.set_thermostat);
            $(document).off('click', '.set-dimmer').on('click', '.set-dimmer', App.set_dimmer);
            $(document).off('click', '.set-slider').on('click', '.set-slider', App.set_dimmer);
            $(document).off('click', '.set-enh').on('click', '.set-enh', App.set_enh);
            $(document).off('click', 'button[data-macro-id]').on('click', 'button[data-macro-id]', App.macro);
            $(document).off('click', '[data-rtype="button"]').on('click', '[data-rtype="button"]', App.button);
            $(document).off('click', '[data-rtype="shift_button"]').on('click', '[data-rtype="shift_button"]', App.button);
        }

        if ( activeView == 'macro' ) {
            $(document).off('click', 'button[data-macro-id]').on('click', 'button[data-macro-id]', App.macro);
        }





        if ( activeView == 'spaces' ) {

            $(document).off('click', 'img[data-rtype="dimmer"]').on('click', 'img[data-rtype="dimmer"]', App.set_dimmer);
            $(document).off('click', 'img[data-rtype="slider"]').on('click', 'img[data-rtype="slider"]', App.set_dimmer);
            $(document).off('click', 'img[data-rtype="enh_counter_var"]').on('click', 'img[data-rtype="enh_counter_var"]', App.set_enh);
            $(document).off('click', 'img[data-rtype="enh_counter_lim"]').on('click', 'img[data-rtype="enh_counter_lim"]', App.set_enh);
            $(document).off('click', 'img[data-rtype="analog_threshold1"]').on('click', 'img[data-rtype="analog_threshold1"]', App.set_thermostat);
            $(document).off('click', 'img[data-rtype="button"]').on('click', 'img[data-rtype="button"]', App.button);
            $(document).off('click', 'img[data-rtype="shift_button"]').on('click', 'img[data-rtype="shift_button"]', App.button);

        }
    },







};

module.exports = App;
