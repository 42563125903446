"use strict";

var App = {

    D: {}, // data read from server
    MACROS: {}, // data read from server
    CONTENT: '', //html with original content
    S: require('localstorage'),
    Ajax: require('ajax'),

    CMD_HELPERS: require('../cmd_helpers'),





    packetArrived: function(data){
        var skey = [data.idb, data.idu, data.rno, data.rtype].join(',');
        $('#dbg').html(skey);

        if ( ! App.D[skey] ) return;

        App.D[skey].hasADCConverted = false;

        App.D[skey].current_state = data.svalue;
        App.D[skey].current_value = data.value;
        App.D[skey].current_timestamp = new Date(data.timestamp);
        App.D[skey].cmd_tpl = '';
        App.D[skey] = App.load_typeid_tpl(App.D[skey], skey);

        $('.subscribe[data-skey="' + skey + '"]').html(App.D[skey].cmd_tpl);

    },



    // Load partial template to show buttons, relays on-off, thermostat etc.
    load_typeid_tpl: function(dev, el){




        dev.cmd_tpl = '';
        dev.skey = el;

        //
        // If it's analog device, calculate correct value
        switch ( parseInt(dev.typeid) ) {
            case 101: // activerelay
            case 102: // passiverelay
                var _tpl = require('views/fav/cmd_relay');
                dev.cmd_tpl = _tpl({
                    ON: dev.current_state == 'on' ? 1 : 0,
                    skey: dev.skey
                });
                break;
            case 103: // sensor
                var _tpl = require('views/fav/cmd_sensor');
                dev.cmd_tpl = _tpl({
                    state_custom: dev.states_icons[dev.current_state].state_custom || dev.current_state
                });
                break;
            case 552: // led
                var _tpl = require('views/fav/cmd_default');
                dev.cmd_tpl = _tpl({
                    current_value: dev.current_state
                });
                break;
            case 104: // button
            case 105: // shifted_button
                var _tpl = require('views/fav/cmd_button');
                dev.cmd_tpl = _tpl(dev);
                break;



            case 201: // thermometer
                var _tpl = require('views/fav/cmd_temp');
                dev.cmd_tpl = _tpl(dev);
                break;
            case 202: // powermeter
                var _tpl = require('views/fav/cmd_default');
                dev.cmd_tpl = _tpl(dev);
                break;
                break;
            case 205: // genericadc
                //var _tpl = require('views/fav/cmd_default');
                //dev.cmd_tpl = _tpl(dev);
                //break;
            case 502: // adcinput
            case 510: // slider
                var analog_type = parseInt(dev.analog_type);
                var _tpl;
                if ( isNaN(analog_type) ) {
                    _tpl = require('views/fav/cmd_default');
                }
                else {

                    App.CMD_HELPERS.adc(dev);

                    switch ( analog_type ) {
                        case 1: // thermometer
                        case 2: // VAC
                        case 3: // W
                        case 4: //%RH
                        case 5: //kPa
                        case 6: //cm
                        case 7: //
                        case 8: // temperature_tcs
                        case 9: // humidity_tcs
                        case 10: // thermistor_16bit
                            _tpl = require('views/fav/cmd_temp');
                            break;
                        default:
                            _tpl = require('views/fav/cmd_default');
                    }
                }
                dev.cmd_tpl = _tpl(dev);
                break;
            case 203: // thermostat
            case 503: // adcthreshold1
            case 504: // adcthreshold2
                App.CMD_HELPERS.adc(dev);
                var _tpl = require('views/fav/cmd_thermostat');
                dev.cmd_tpl = _tpl(dev);
                break;


            case 204: // dimmer
                var _tpl = require('views/fav/cmd_dimmer');
                dev.cmd_tpl = _tpl({
                    current_value: dev.current_value,
                    skey: dev.skey
                });
                break
            case 550: // relative timer
            case 551: // periodic timer
                break;
            case 551: // periodic timer
                break;
            case 600: // info - relative_timer - button
                if ( typeof dev.commands !== 'undefined' && dev.commands == 'click' ) {
                    var _tpl = require('views/fav/cmd_button');
                    dev.cmd_tpl = _tpl(dev);
                }
                break;
            case 701: // enh_counter_lim
                dev.analog_type = 7;
                App.CMD_HELPERS.adc(dev);
                var _tpl = require('views/fav/cmd_enh');
                dev.cmd_tpl = _tpl(dev);
                break;
            default:
                var _tpl = require('views/fav/cmd_default');
                var data = {
                    current_value: dev.current_value || dev.current_state
                };
                dev.cmd_tpl = _tpl(data);
        }
        if ( dev.rtype == 'enh_counter_var' ) {
            dev.analog_type = 7;
            App.CMD_HELPERS.adc(dev);
            var _tpl = require('views/fav/cmd_enh');
            dev.cmd_tpl = _tpl(dev);
        }
        return dev;
    },



    run: function(that){
        //var action = $(that).attr('ctrl-data');
        App.CMD_HELPERS.show_ajax_wait();

        App.S.write('activeview', 'fav');

        App.CMD_HELPERS.bind();

        $('nav.bar a').removeClass('active');
        $(that).addClass('active');

        if ( ! F.data ) return;

        var data = F.data;

        if ( ! data.fav_devices ) {
            App.CMD_HELPERS.CONTENT = 'ΔΕΝ ΥΠΑΡΧΟΥΝ ΑΓΑΠΗΜΕΝΑ !';
            App.CMD_HELPERS.show_content();
            return false;
        }


        //if (data.fav_order ) data.fav_order = JSON.parse(data.fav_order);

        App.D = {};
        App.MACROS = {};

        if ( data.fav_order ) {
            data.fav_order.map(function(skey){

                if ( skey.substr(0, 2) != 's-' ) {
                    // It's a macro
                    var id = skey.split('-')[1];

                    data.macros.map(function(el){
                        if ( el.id == id ) {
                            var _tpl = require('views/fav/cmd_macro');
                            el.label = el.macro_name;
                            el.cmd_tpl = _tpl(el);

                            App.D[el.label] = el;
                            App.MACROS[el.id] = el;
                        }
                    });
                }
                else {
                    // Normal device

                    skey = skey.substr(2).replace(/-/g, ',');
                    var dev = data.devices[skey];
                    App.D[skey] = App.load_typeid_tpl(dev, skey);

                }

            });
        }
        else {
            data.fav_devices.map(function(el, i){
                var dev = data.devices[el];
                App.D[el] = App.load_typeid_tpl(dev, el);
            });
            data.macros.map(function(el, i){
                if ( parseInt(el.fav) != 1 ) return;
                var _tpl = require('views/fav/cmd_macro');
                el.label = el.macro_name;
                el.cmd_tpl = _tpl(el);

                App.D[el.label] = el;
                App.MACROS[el.id] = el;
            });
        }



        //console.log(App.D);
        //console.log(App.MACROS);
        App.CMD_HELPERS.D = App.D;
        App.CMD_HELPERS.MACROS = App.MACROS;

        var tmpl = require('views/fav/fav');
        App.CMD_HELPERS.CONTENT = tmpl({data:App.D});
        App.CMD_HELPERS.show_content();

        $.Topic('packetArrived').subscribe(App.packetArrived);


    }



}

module.exports = App;
