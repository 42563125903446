var __templateData = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"content-padded\">\n    \n    <div id=\"frm-err\" class=\"err\"></div>\n\n    <input id=\"host\" type=\"text\" placeholder=\"Host\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"host") || (depth0 != null ? lookupProperty(depth0,"host") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"host","hash":{},"data":data,"loc":{"start":{"line":5,"column":59},"end":{"line":5,"column":67}}}) : helper)))
    + "\">\n    <input id=\"username\" name=\"username\" type=\"text\" placeholder=\"username\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":6,"column":83},"end":{"line":6,"column":95}}}) : helper)))
    + "\">\n    <input id=\"password\" name=\"password\" type=\"password\" placeholder=\"password\">\n\n    <button ctrl-click=\"login\" ctrl-data=\"login\" class=\"btn btn-primary btn-block\">Login</button>\n\n\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}