var __templateData = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div style=\"text-align:center;margin-top:40px;\">\n    <img src=\"ajax-loader.gif\" alt=\"ΠΑΡΑΚΑΛΩ ΠΕΡΙΜΕΝΕΤΕ...\" title=\"ΠΑΡΑΚΑΛΩ ΠΕΡΙΜΕΝΕΤΕ...\" width=\"48\" height=\"48\"/>\n</div>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}