"use strict";

var App = {
    
    S: require('localstorage'),

    
    
    
    Post: function(PARAMS, URL, cb){
        
        var host = App.S.read('host');
        var user = JSON.parse(App.S.read('user'));
        
        PARAMS = PARAMS || {};
        if ( ! PARAMS.token && user )  PARAMS.token = user.token;
        $.post(
            host + URL,
            PARAMS,
            function(data){
                if(data && data.err){
                    cb(data.err);
                    return false;
                    }
                cb(null, data);
            },
            'json'
        )
        .fail(function(jqXHR, textStatus, errorThrown){

            var msg = "Sorry but there was an error: ";
            var html = msg + jqXHR.status + " " + jqXHR.statusText + "<br><hr>" + jqXHR.responseText;
            cb(jqXHR.status + " " + jqXHR.statusText);
        });
        
    },
    
    
    
    
    
    
    


}

module.exports = App;
