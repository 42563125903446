"use strict";

var App = {

    S: require('localstorage'),
    Ajax: require('ajax'),
    CMD_HELPERS: require('../cmd_helpers'),
    
    init: function init() {
        
        var dfd = $.Deferred();
         
        var user = JSON.parse(App.S.read('user'));
        if ( ! user.token || ! user.database ) {
            var err = 'No usernamd or Database !';
            dfd.reject(err);
            return;
        }
        var PARAMS = {
            token: user.token,
            database: user.database
        };
        App.Ajax.Post(PARAMS, 'fav', function(err, data){
            if (err ) {
                dfd.reject(err);
            }
            else {

                if ( data.fav_order ) {
                    var fo = JSON.parse(data.fav_order);
                    data.fav_order = fo;
                }
                F.data = data || {};
                dfd.resolve();
            }
        });
        
        return dfd.promise();
    },
};

module.exports = App;
