"use strict";

var App = {

    run: function run(that) {
        var tmpl = require('views/v2');
        var html = tmpl({v:$(that).val()});
        $('#data').empty().append(html);
        $('a[ctrl-click]').button();
    }
};

module.exports = App;
