"use strict";

var App = {

    run: function run(that) {
        require('application').init();
    }
};

module.exports = App;
