var __templateData = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"bar bar-nav\">\n    <a href=\"#\" class=\"icon icon-left-nav pull-left cmd-set-go-back\"></a>\n    <h1 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":31}}}) : helper)))
    + "</h1>\n</header>\n\n\n<div style=\"margin-top:60px;text-align:center;\">\n    \n    <button class=\"btn set-temp-back\"><span class=\"icon icon-back\"></span></button>\n    \n    <button class=\"btn set-temp-down\"><span class=\"icon icon-down\"></span></button>\n\n    <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"current_value") || (depth0 != null ? lookupProperty(depth0,"current_value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_value","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":47}}}) : helper)))
    + "\" style=\"height:50px; text-align:center;width:100px;\"/>\n\n\n    <button class=\"btn set-temp-up\"><span class=\"icon icon-up\"></span></button>\n    <button class=\"btn set-temp-forward\"><span class=\"icon icon-forward\"></span></button>\n\n\n    <button class=\"btn btn-positive btn-block set-temp-button\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":20,"column":74},"end":{"line":20,"column":82}}}) : helper)))
    + "\">Set</button>\n\n</div>\n\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}