"use strict";

var App = {

    user: JSON.parse(require('localstorage').read('user')),
    
    Ajax: require('ajax'),

    postCommand: function(_cmd){
        var user = JSON.parse(require('localstorage').read('user'));
        if ( ! user ) return false;

        //
        // Check if command allowed to be executed...
        var key = [_cmd.idb, _cmd.idu, _cmd.rno, _cmd.rtype].join(',');
        if ( typeof F.data.devices[key] !== 'undefined' && parseInt(F.data.devices[key].allow_user_cmd) === 0 ) return false;

    
        var PARAMS = {
            token: user.token,
            cmd: _cmd,
        }
        App.Ajax.Post(PARAMS, 'cmd', function(err, data){
            if ( err ) {
                F.CMD_HELPERS.show_err(JSON.stringify(err));
            }
        });

    },

    onOff : function(skey, device){

        var user = JSON.parse(require('localstorage').read('user'));
        if ( ! user ) return false;

        var k = skey.split(',');

        if ( k[3] == 'relative_timer' ) k[3] = 'relative_timer_expired';
        if ( k[3] == 'periodic_timer' ) k[3] = 'periodic_timer_expired';
        if ( k[3] == 'period_timer' ) k[3] = 'periodic_timer_expired';
        
        var _cmd = {
            idb: k[0],
            idu: k[1],
            rno: k[2],
            rtype: k[3],
            command: k[3],
            cmd: '',
            uid: user.gateway,
        };
        var _c = {
            'on' : 'off',
            'armedon' : 'armedoff',
            'off' : 'on',
            'armedoff' : 'armedon',
        }

        var state = device.current_state;
        var commands = device.commands;
        
        if ( _c[state] ) _cmd.cmd = _c[state];
        if ( _cmd.cmd == '' && commands && commands.length ) {
            _cmd.cmd = Array.isArray(commands) && commands[0] || commands;
        }
console.log(_cmd);

        App.postCommand(_cmd);

    },



    macroCmd: function(m){
        var user = JSON.parse(require('localstorage').read('user'));
        if ( ! user ) return false;

        var commands = JSON.parse(m.commands);

        for ( var c=0; c<commands.length; c++ ) {
            var allow_user_cmd = commands[c].allow_user_cmd || null;

            if ( ! allow_user_cmd ) break;

            var cmd = commands[c].command;
            if ( cmd == 'click' ) {
                cmd = commands[c].rtype; //button or shifted_button
            }

            var v = '';
            if ( cmd == 'set' && typeof commands[c].setValue !== 'undefined' ) {

                var analog_type = parseInt(commands[c].analog_type);
                var analog_error = parseFloat(commands[c].analog_error);
                var analog_fluid_density = parseFloat(commands[c].analog_fluid_density);
                v = F.toADC(parseFloat(commands[c].setValue), analog_type, analog_error, analog_fluid_density);

            }
            //var v = ( typeof commands[c].setValue === 'undefined' ) ? '' : commands[c].setValue;

            var k = {
                idb: commands[c].idb,
                idu: commands[c].idu,
                rno: commands[c].rno,
                rtype: commands[c].rtype,
                command: commands[c].rtype, //(the type: report, relay, cardun etc.)
                cmd: cmd, //(the command: on/off/click etc.)
                value: v, //(the value if command is SET)
                uid: user.gateway,
            }

            if ( k.rtype == 'relative_timer' && commands[c].commands == 'click' ) {
                k.rtype = 'relative_timer_expired';
                k.command = 'relative_timer_expired';
                k.cmd = 'click';
            }
            

            App.postCommand(k);
        }
    },

    rawCmd: function(_cmd){
        if ( _cmd.cmd != '' ) {
            App.postCommand(_cmd);
        };
    },
    
    report: function(){
        var user = JSON.parse(require('localstorage').read('user'));
        if ( ! user ) return false;
        var _cmd = {
            idb: 0,
            idu: 0,
            rno: 0,
            type: '',
            rtype: '',
            command: 'report',
            cmd: '',
            //~ uid: F.uid,
            uid: user.gateway,
        };                
        App.postCommand(_cmd);
    },
    
    restart: function(){
        var user = JSON.parse(require('localstorage').read('user'));
        if ( ! user ) return false;
        var _cmd = {
            idb: 0,
            idu: 0,
            rno: 0,
            type: '',
            rtype: '',
            command: 'restart',
            cmd: '',
            //~ uid: F.uid,
            uid: user.gateway,
        };                

        App.postCommand(_cmd);
    },
    






};

module.exports = App;
