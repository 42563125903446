"use strict";

var App = {
    
    supports: function() {
        try {
            return 'localStorage' in window && window['localStorage'] !== null;
        } catch (e) {
            return false;
        }
    },

    write: function(key, val){
        if ( ! App.supports() ) return false;
        window.localStorage.setItem(key, val);
        return true;
    },

    read: function(key){
        if ( ! App.supports() ) return false;
        return window.localStorage.getItem(key);
    },

    readAll: function(){
        if ( ! App.supports() ) return false;
        return window.localStorage;

    },

    delete: function(key){
        if ( ! App.supports() ) return false;
        return window.localStorage.removeItem(key);
    },
}

module.exports = App;
