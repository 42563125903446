"use strict";

var App = {
    S: require('localstorage'),
    Ajax: require('ajax'),
    CMD_HELPERS: require('../cmd_helpers'),
    D: {},


    packetArrived: function(data){
        var skey = [data.idb, data.idu, data.rno, data.rtype].join(',');
        var id_img = 'img-' + [data.idb, data.idu, data.rno, data.rtype].join('-');

        if ( ! App.D.devices[skey] ) return;

        App.D.devices[skey].hasADCConverted = false;

        App.D.devices[skey].current_state = data.svalue;
        App.D.devices[skey].current_value = data.value;
        App.D.devices[skey].current_timestamp = new Date(data.timestamp);

        var dev = App.D.devices[skey];
        var space = dev.spaces_arr[0];
        var x = space.x;
        var y = space.y;
        var state = dev.current_state;
        if ( typeof dev.states_icons[state] === 'undefined' && state != 'default' ) state = 'default';
        if ( typeof dev.states_icons[state] === 'undefined' && state == 'default' ) state = 'unknown';
        if ( typeof dev.states_icons[state] === 'undefined' ) return;
        var state_icon_w = dev.states_icons[state].iconw;
        var state_icon_h = dev.states_icons[state].iconh;
        var title = App.checkTypeID(dev);




        $('#' + id_img)
            .attr({
                'src': "data:image/png;base64," + dev.states_icons[state].icon,
                'data-state': dev.current_state,
            });

        if ( title != '' ) {
            var info = $('#info-' + skey.replace(/,/g,'-'));
            if ( info.length ) info.html(title);
        }
    },


    checkTypeID: function(dev){
        var title = '';
        //
        // If it's analog device, calculate correct value
        switch ( parseInt(dev.typeid) ) {
            case 101: // activerelay
            case 102: // passiverelay
            case 103: // sensor
            case 552: // led
            case 104: // button
            case 105: // shifted_button
            case 201: // thermometer
            case 202: // powermeter
            case 205: // genericadc
                //var _tpl = require('views/fav/cmd_default');
                //dev.cmd_tpl = _tpl(dev);
                break;
            case 502: // adcinput
            case 510: // slider
                var analog_type = parseInt(dev.analog_type);
                var _tpl;
                if ( ! isNaN(analog_type) ) {

                    App.CMD_HELPERS.adc(dev);

                    switch ( analog_type ) {
                        case 1: // thermometer
                        case 2: // VAC
                        case 3: // W
                        case 4: //%RH
                        case 5: //kPa
                        case 6: //cm
                        case 7: //
                        case 8: // temperature_tcs
                        case 9: // humidity_tcs
                        case 10: // thermistor_16bit
                            title = dev.current_value + ' ' + dev.analogTP;
                            break;
                        default:
                    }
                }
                break;
            case 203: // thermostat
            case 503: // adcthreshold1
            case 504: // adcthreshold2
                App.CMD_HELPERS.adc(dev);
                title = dev.current_value + ' ' + dev.analogTP;
                break;


            case 204: // dimmer
                title = dev.current_value;
                break
            case 550: // relative timer
            case 551: // periodic timer
                break;
            case 600: // info - relative_timer - button
                if ( typeof dev.commands !== 'undefined' && dev.commands == 'click' ) {
                    var _tpl = require('views/fav/cmd_button');
                    dev.cmd_tpl = _tpl(dev);
                }
                break;
            case 701: // enh_counter_lim
                dev.analog_type = 7;
                App.CMD_HELPERS.adc(dev);
                title = dev.current_value;
                break;
            default:
        }

        if ( dev.rtype == 'enh_counter_var' ) {
            dev.analog_type = 7;
            App.CMD_HELPERS.adc(dev);
            title = dev.current_value;
        }

        return title;
    },


    show_space: function(id){
        if ( ! F.data ) return;

        var data = F.data;

        var dataSpace;
        data.spaces.map(function(el){
            if ( el.id == id ) dataSpace = el;
        });

        if ( ! dataSpace ) return;

        var dimensions = dataSpace.icon_dimensions;
        var url = "data:image/png;base64," + dataSpace.icon;
        url = url.replace(/(\r\n|\n|\r)/gm, "");

        $('#div-space')
            .css('background-image', "url('" + url + "')")
            .css({'height': dimensions.height + 'px', 'width':dimensions.width + 'px'})
            ;

        //$("#img-space").attr("src", "data:image/png;base64," + data.spaces[0].icon);

        var _static = 'static/images/';
        data.space_devices.map(function(skey){

            var dev = data.devices[skey];

            var space;
            //
            // Find the space for device
            dev.spaces_arr.map(function(el){
                if ( el.space_device_id_space == id ) space = el;
            });
            if ( ! space ) return;
            var x = space.x;
            var y = space.y;
            var state = dev.current_state || 'default';
            if ( typeof dev.states_icons[state] === 'undefined' && state != 'default' ) state = 'default';
            if ( typeof dev.states_icons[state] === 'undefined' && state == 'default' ) state = 'unknown';
            if ( typeof dev.states_icons[state] === 'undefined' ) return;
            var state_icon_w = dev.states_icons[state].iconw;
            var state_icon_h = dev.states_icons[state].iconh;
            var title = App.checkTypeID(dev);


            var img = $('<img/>')
                .addClass('space-image')
                .attr({
                    'src': "data:image/png;base64," + dev.states_icons[state].icon,
                    'id': 'img-' + skey.replace(/,/g,'-'),
                    'data-rtype': dev.rtype,
                    'data-state': dev.current_state,
                    'data-skey': skey,
                })
                .css({
                    'width':state_icon_w + 'px',
                    'height':state_icon_h + 'px',
                    'position':'absolute',
                    'top' : y + 'px',
                    'left' : x + 'px',
                });
            if ( title != '' ) {
                var sp = $('<span/>');
                sp.attr('id', 'span-' + skey.replace(/,/g,'-'));

                var info = $('<span/>').addClass('space_val').html(title);
                info.attr('id', 'info-' + skey.replace(/,/g,'-'));
                info.css({
                    'top' : y + 'px',
                    'left' : (x + 25) + 'px',
                });
                sp.append(img);
                sp.append(info);
                $('#div-space').append(sp);
            }
            else {
                $('#div-space').append(img);
            }


        });

        App.CMD_HELPERS.CONTENT = $('div.content').html();

    },




    init_multi_space: function(data){

        var tmpl = require('views/spaces/list');
        App.CMD_HELPERS.CONTENT = tmpl({data:data.spaces});
        App.CMD_HELPERS.show_content();

        $(document).off('click', '#div-sel-space button[data-id]').on('click', '#div-sel-space button[data-id]', function(){
            var tmpl = require('views/spaces/spaces');

            App.CMD_HELPERS.CONTENT = tmpl();
            App.CMD_HELPERS.show_content();
            var id = $(this).attr('data-id');
            App.show_space(id);
        });

    },

    run: function(that){
        //var action = $(that).attr('ctrl-data');

        App.CMD_HELPERS.show_ajax_wait();

        App.S.write('activeview', 'spaces');

        App.CMD_HELPERS.bind();

        $('nav.bar a').removeClass('active');
        $(that).addClass('active');


        if ( ! F.data ) return;

        var data = F.data;

        App.D = data;
        console.log(App.D);

        if ( data.spaces.length == 0 ) {
            App.CMD_HELPERS.CONTENT = 'ΔΕΝ ΥΠΑΡΧΟΥΝ ΧΩΡΟΙ !';
            App.CMD_HELPERS.show_content();
            return false;
        }

        var tmpl = require('views/spaces/spaces');

        App.CMD_HELPERS.CONTENT = tmpl();
        App.CMD_HELPERS.show_content();
        App.CMD_HELPERS.D = App.D;

        if ( data.spaces.length == 1 ) {
            App.show_space(data.spaces[0].id);
        }
        else {
            App.init_multi_space(data);
        }


        $.Topic('packetArrived').subscribe(App.packetArrived);







    }



}

module.exports = App;
