var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <button class=\"btn btn-positive\" data-rtype=\"relay\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":4,"column":67},"end":{"line":4,"column":75}}}) : helper)))
    + "\">ON</button>\n<!--\n    <div class=\"toggle active\" data-rtype=\"relay\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":6,"column":61},"end":{"line":6,"column":69}}}) : helper)))
    + "\" >\n        <div class=\"toggle-handle\"></div>\n    </div>\n-->\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <button class=\"btn\" data-rtype=\"relay\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":62}}}) : helper)))
    + "\">OFF</button>\n<!--\n    <div class=\"toggle\" data-rtype=\"relay\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":13,"column":54},"end":{"line":13,"column":62}}}) : helper)))
    + "\">\n        <div class=\"toggle-handle\"></div>\n    </div>\n-->\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"pull-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ON") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}