var __templateData = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"pull-right\">\n    \n    <button class=\"btn set-thermostat\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":58}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"current_value") || (depth0 != null ? lookupProperty(depth0,"current_value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_value","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":77}}}) : helper)))
    + " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"analogTP") || (depth0 != null ? lookupProperty(depth0,"analogTP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"analogTP","hash":{},"data":data,"loc":{"start":{"line":3,"column":78},"end":{"line":3,"column":92}}}) : helper))) != null ? stack1 : "")
    + "</button>\n    \n</span>\n\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}