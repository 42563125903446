"use strict";

var App = {
    
    D: {}, // data read from server
    MACROS: {}, // data read from server
    CONTENT: '', //html with original content
    S: require('localstorage'),
    Ajax: require('ajax'),

    CMD_HELPERS: require('../cmd_helpers'),

    

    run: function(that){
        //var action = $(that).attr('ctrl-data');
        App.CMD_HELPERS.show_ajax_wait();

        App.S.write('activeview', 'macro');

        App.CMD_HELPERS.bind();
        
        $('nav.bar a').removeClass('active');
        $(that).addClass('active');
        
        if ( ! F.data ) return;
        
        var data = F.data;

        if ( ! data.macros.length ) {
            App.CMD_HELPERS.CONTENT = 'ΔΕΝ ΥΠΑΡΧΟΥΝ MACROS !';
            App.CMD_HELPERS.show_content();
            return false;
        }

        App.D = {};
        App.MACROS = {};

        data.macros.sort(function(a, b){
            if ( a.macro_name > a.macro_name ) return 1;
            if ( a.macro_name < a.macro_name ) return -1;
            return 0;
        });

        data.macros.map(function(el, i){
            //if ( parseInt(el.fav) != 1 ) return;
            var _tpl = require('views/macro/macro');
            el.label = el.macro_name;
            el.cmd_tpl = _tpl(el);

            App.D[el.label] = el;
            App.MACROS[el.id] = el;
        });

        
        
        //console.log(App.D);
        //console.log(App.MACROS);
        App.CMD_HELPERS.D = App.D;
        App.CMD_HELPERS.MACROS = App.MACROS;
        
        var tmpl = require('views/macro/main');
        App.CMD_HELPERS.CONTENT = tmpl({data:App.D});
        App.CMD_HELPERS.show_content();

        
    }



}

module.exports = App;
