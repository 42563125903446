"use strict";

F.selectGateway = function (){

    $('body').addClass("loading");
    
    var uid = $(this).val();

    var user = App.S.read('user');

    var CMD_HELPERS = require('../cmd_helpers');


    if ( ! user ) return false;
    user = JSON.parse(user);
    user.installations.map(function (o){
        if ( o.uid == uid ) {
            user.gateway = uid;
            user.gateway_active = o.gateway_active;
            user.database = o.database;
            user.id_installation = o.id_installation;
            
            App.S.write('userActiveGW', uid);
            
        }
    });

    App.S.write('user', JSON.stringify(user));


    // Inform server to send us events for current gateway.
    F.socket.emit('uid', user.gateway);
    F.uid = user.gateway;
    
    $('div.content').empty();

    setTimeout(function(){
        require('load_data').init().then(function(){
            $('div.content').empty().html(new Date());
            var view = App.S.read('activeview') || 'fav';
            $('nav.bar a[ctrl-click="' + view + '"]').trigger('click');

            $('body').removeClass("loading");

            CMD_HELPERS.bind();


        }, function(err){
            CMD_HELPERS.show_err(JSON.stringify(err));
            if ( cb ) cb(err);
        });
    }, 1500);


};
    

var App = {
    
    S: require('localstorage'),
    Ajax: require('ajax'),

    CMD_HELPERS: require('../cmd_helpers'),

    isLoggedIn: function(cb){
        App.login(cb);
    },
    
    
    login_form: function(){

        var tmpl = require('views/login/login');
        var nhost = location.hostname;
        nhost = nhost.replace(/http:\/\//, '');
        nhost = ['http://', nhost, ':3000/m'].join('');
        var data = {
            host: App.S.read('host') || nhost,
        }
        
        var user = JSON.parse(App.S.read('user'));
        if ( user ) data.username = user.username;
        var html = tmpl(data);
        $('div.content').empty().html(html);
        $('#username').focus();
    },
    

    
    // Actual Login
    login: function(cb){
        var PARAMS = {};
        
        if ( cb ) {
            var user = App.S.read('user');
            if ( ! user ) {cb('not Loggedin'); return false;}
            
            user = JSON.parse(user);            
            
            PARAMS = {
                username: user.username,
                password: user.password,
            }
        }
        else {
            
            var host = $('#host').val();
            
            if ( host == '' ) {
                if ( cb ) cb('No Host!');
                return;
            }
            
            if ( host.substr(-1) != '/' ) host += '/';
            
            App.S.write('host', host);
            
            PARAMS = {
                username: $('#username').val(),
                password: $('#password').val(),
            }
        }



        var loginOK = function (data){
            // Login OK.
            PARAMS.database = data.database;
            PARAMS.gateway = data.gateway;
            PARAMS.gateway_active = data.gateway_active;
            PARAMS.token = data.token;
            PARAMS.browserConfig = data.browserConfig;
            PARAMS.id_installation = data.id_installation;
            PARAMS.installations = data.installations;
            
            
            // Fill element for user to select gateway.
            var userActiveGW = data.gateway;
            App.S.write('userActiveGW', userActiveGW);
            
            var sel = $('#selectGateway');
            sel.empty();
            data.installations.map(function (o){
                var opt = $('<option/>');
                opt.val(o.uid);
                opt.html(o.gateway);
                if ( o.uid == userActiveGW ) {
                    opt.attr('selected', 'selected');

                    PARAMS.database = o.database;
                    PARAMS.gateway = o.uid;
                    PARAMS.gateway_active = o.gateway_active;
                    PARAMS.id_installation = o.id_installation;

                }
                sel.append(opt);
            });


            if ( ! isNaN(PARAMS.gateway_active) && parseInt(PARAMS.gateway_active) == 0 ) {
                return false;
            }

            App.S.write('user', JSON.stringify(PARAMS));


            F.lang = data.lang;



            //
            // Set menu labels depending on active language
            $('a[ctrl-click="fav"] .tab-label').html(F.lang.f_u_favorites);
            $('a[ctrl-click="macro"] .tab-label').html(F.lang.f_u_macros);
            $('a[ctrl-click="spaces"] .tab-label').html(F.lang.f_space_menu);
            $('a[ctrl-click="login"] .tab-label').html(F.lang.f_logout_link);
            
            
            require('socket').init();

            setTimeout(function(){
                require('load_data').init().then(function(){
                    if ( cb ) {
                        cb(null, App.S.read('user'));
                    }
                    else {
                        $('div.content').empty().html(new Date());
                        var view = App.S.read('activeview') || 'fav';
                        $('nav.bar a[ctrl-click="' + view + '"]').trigger('click');
                    }
                }, function(err){
                    App.CMD_HELPERS.show_err(JSON.stringify(err));
                    if ( cb ) cb(err);
                });
            }, 500);
        }




        App.CMD_HELPERS.show_ajax_wait();





        App.Ajax.Post(PARAMS, 'login', function(err, data){
            if (err ) {
                App.CMD_HELPERS.show_err(JSON.stringify(err));
                if ( cb ) cb(err);
            }
            else {
                // Login OK.
                loginOK(data);
            }
        });
        
        return false;
    },
    
    logout: function(){
        App.S.delete('user');
        App.S.delete('userActiveGW');
        location.reload(true);
        //App.login_form();
    },
    
    
    run: function(that){
        var action = $(that).attr('ctrl-data');
        if ( action == 'login' ) {App.login(); return;}
        if ( action == 'logout' ) {App.logout(); return;}
        $('div.content').empty().html('Unknown login run action: ' + action);
    }



}

module.exports = App;
