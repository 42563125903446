var __templateData = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"table-view-cell\">\n        \n        <a href=\"#\" class=\"a-outer\">\n            \n            <div style=\"display: inline-block; padding: 0px; height: 34px; width: 34px; margin: -10px 5px -10px -10px; position: relative; top: 8px;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"iconfile_bin") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":10,"column":23}}})) != null ? stack1 : "")
    + "                &nbsp;\n            </div>\n            \n            <span style=\"font-size:0.7em;\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":25}}}) : helper)))
    + "\n            </span>\n            \n            <span class=\"subscribe\" data-skey=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"skey") || (depth0 != null ? lookupProperty(depth0,"skey") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"skey","hash":{},"data":data,"loc":{"start":{"line":18,"column":47},"end":{"line":18,"column":55}}}) : helper)))
    + "\" style=\"font-size:0.7em;\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cmd_tpl") || (depth0 != null ? lookupProperty(depth0,"cmd_tpl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cmd_tpl","hash":{},"data":data,"loc":{"start":{"line":18,"column":82},"end":{"line":18,"column":95}}}) : helper))) != null ? stack1 : "")
    + "</span>\n            \n        </a>\n\n        \n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img src=\"data:image/png;base64,"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"iconfile_bin") || (depth0 != null ? lookupProperty(depth0,"iconfile_bin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iconfile_bin","hash":{},"data":data,"loc":{"start":{"line":9,"column":48},"end":{"line":9,"column":64}}}) : helper)))
    + "\" alt=\"\" title=\"\" width=\"20\" height=\"20\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"table-view\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "        <li class=\"table-view-cell\">&nbsp;</li>\n</ul>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}