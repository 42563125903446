"use strict";

var App = {

    S: require('localstorage'),

    init: function(){

        var user = JSON.parse(App.S.read('user'));
        
        var cfg = { 
            port: user.browserConfig.AltPort, 
            query: "fyes_id='" + user.gateway + "'&token='" + user.token + "'",
        };
        
        F.uid = user.gateway;
        F.socket = io.connect(user.browserConfig.host, cfg);

console.log(cfg);



        F.socket.on('connect', function(){
            $.Topic('connected').publish('socket connected!');

            F.socket.emit('id_installation', user.id_installation);
            F.socket.emit('uid', user.gateway);

        });

        /*
        F.socket.on('program', function(data){
            $.Topic('program').publish(data);
        });
        */

        F.socket.on('reconnect', function(number){
            location.reload(true);
        });

        F.socket.on('packet_from_serial', function(data){
            //$.Topic('packetArrived').publish(data);
        });

        F.socket.on('command completed', function(data){
            console.log('command completed');
            //$.Topic('packetArrived').publish(data);
        });

        F.socket.on('command error', function(err){
            console.log(err);
            //$.Topic('packetArrived').publish(data);
        });

        F.socket.on('data', function(data){
//~ console.log('data: ', data);
//~ if ( data.ev == 'condition_message' ) {
    //~ console.log(data);
//~ }
//if ( ['analog_input','motion_sensor','del_mot_sens'].indexOf(data.rtype) == -1 ) console.log(data);


            // Ignore messages from other gateways.
            // They came here by mistake (!!!???)
            if ( data.uidSource && F.uid.toLowerCase() != data.uidSource.toLowerCase() ) return;
            
            $.Topic('packetArrived').publish(data);
        });
        
        
    
    }




};

module.exports = App;
