"use strict";

var App = {

    init: function init() {
        $(document).off('click', '[ctrl-click]').on('click', '[ctrl-click]', function(){
            var c = $(this).attr('ctrl-click');
            require('controllers/' + c).run(this);
        });

        $(document).off('change', '[ctrl-change]').on('change', '[ctrl-change]', function(){
            var c = $(this).attr('ctrl-change');
            require('controllers/' + c).run(this);
        });
        
        
        // Prevent forms from auto submit
        $(document).off('submit', 'div.content form').on('submit', 'div.content form', function(){
            return false;
        });

        $(document).off('change', '#selectGateway').on('change', '#selectGateway', F.selectGateway);

        /*
        $(document).on({
            ajaxStart: function() { $('body').addClass("loading");    },
            ajaxStop: function() { $('body').removeClass("loading"); }    
        });
        */



    }
};

module.exports = App;
