"use strict";

var App = {
    
    run: function run(that) {
        var tmpl = require('views/v1');
        var html = tmpl({v:new Date()});

        $('#aaaa').html(html);

        
        
    }
};

module.exports = App;
