"use strict";

var App = {

    S: require('localstorage'),
    L: require('controllers/login'),

    init: function init() {
        //var tmpl = require('views/list');
        //var html = tmpl();
        //$('div.content').append(html);

        F.CMD_HELPERS = require('./cmd_helpers');
  
        if ( ! App.S.supports() ) {
            alert('Your browser does not support local storage!');
            return false;
        }
        
        $(document).ready(function(){
            App.L.isLoggedIn(function(err, user){
                if (err) {
                    $('nav.bar a').removeClass('active');
                    App.L.login_form();
                }
                else {
                    $('div.content').empty().html(new Date());
                    var view = App.S.read('activeview') || 'fav';
                    $('nav.bar a[ctrl-click="' + view + '"]').trigger('click');
                }
            });
        
        });

        require('bindings').init();
        
    },
    
    
    
    
    // source: http://davidwalsh.name/pubsub-javascript
    events: (function(){
        var topics = {};
        var hOP = topics.hasOwnProperty;

        return {
            subscribe: function(topic, listener) {
                // Create the topic's object if not yet created
                if(!hOP.call(topics, topic)) topics[topic] = [];

                // Add the listener to queue
                var index = topics[topic].push(listener) -1;

                // Provide handle back for removal of topic
                return {
                    remove: function() {
                        delete topics[topic][index];
                    }
                };
            },
            publish: function(topic, info) {
                // If the topic doesn't exist, or there's no listeners in queue, just leave
                if(!hOP.call(topics, topic)) return;

                // Cycle through topics queue, fire!
                topics[topic].forEach(function(item) {
                    item(info != undefined ? info : {});
                });
            }
        };
    })()
};

module.exports = App;


/*
 *
 *
 *
 * Publish / Subscribe topics
 *
 * http://api.jquery.com/jQuery.Callbacks/
 *
 */
var topics = {};
$.Topic = function( id ) {
    var callbacks, method,
    topic = id && topics[ id ];
    if ( !topic ) {
        callbacks = $.Callbacks("unique");
        topic = {
            publish: callbacks.fire,
            subscribe: callbacks.add,
            unsubscribe: callbacks.remove
        };
        if ( id ) {
            topics[ id ] = topic;
        }
    }
    return topic;
};
