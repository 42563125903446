var __templateData = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"pull-right\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"current_value") || (depth0 != null ? lookupProperty(depth0,"current_value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"current_value","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":42}}}) : helper)))
    + " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"analogTP") || (depth0 != null ? lookupProperty(depth0,"analogTP") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"analogTP","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":57}}}) : helper))) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}